import { FuegoProvider } from "@nandorojo/swr-firestore";
import { useRouter } from "next/router";
import React, { useEffect } from "react";
import "tailwindcss/tailwind.css";
import { analytics, firebase, Fuego } from "../lib/firebase-client";
import { AuthProvider } from "../lib/services/auth";

const fuego = new Fuego(firebase);

export default function App({ Component, pageProps }) {
  const routers = useRouter();

  useEffect(() => {
    const logEvent = (url) => {
      analytics().setCurrentScreen(url);
      analytics().logEvent("screen_view");
    };

    routers.events.on("routeChangeComplete", logEvent);
    //For First Page
    logEvent(window.location.pathname);

    //Remvove Event Listener after un-mount
    return () => {
      routers.events.off("routeChangeComplete", logEvent);
    };
  }, []);

  // exclude embed widgets and homepage from checking auth state
  if (routers.route?.startsWith("/widgets/") || routers.route === "/") {
    return <Component {...pageProps} />;
  }

  return (
    <AuthProvider>
      <FuegoProvider fuego={fuego}>
        <Component {...pageProps} />
      </FuegoProvider>
    </AuthProvider>
  );
}
